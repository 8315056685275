const members = [
  {
    id: "148",
    name: "Nikillan Rajesh",
    nickname: "niKILLan",
    designation: "Team Manager",
    facebook: null,
    mail: "mailto:nikillanrajesh@gmail.com",
    linkedin: "https://www.linkedin.com/in/nikillan-rajesh/",
    github: "https://github.com/Nikillanr"
  },
  {
    id: "147",
    name: "Gaurav Pradeep",
    nickname: "9.81-SPOT",
    designation: "Technical Head",
    facebook: null,
    mail: "mailto:gauravpradeep2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/gaurav-pradeep-352253255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "142",
    name: "Nirbhay Aher",
    nickname: "nOP-AMP",
    designation: "Research and Development Head",
    facebook: null,
    mail: "mailto:nirbhayaher@gmail.com",
    linkedin: "https://www.linkedin.com/in/nirbhay-aher-338708156/",
    github: null
  },
  {
    id: "149",
    name: "Aaryan Takayuki Panigrahi",
    nickname: "TAKAYUKI SIN(TT)",
    designation: "AI Head",
    facebook: null,
    mail: "mailto:aaryan.t.panigrahi@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "150",
    name: "Naman Kumar",
    nickname: "Nah man",
    designation: "Sensing and Automation Head",
    facebook: null,
    mail: "mailto:naman.kmr246@gmail.com",
    linkedin: "https://www.linkedin.com/in/naman-kumar-374b6a2a9/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/00naman"
  },
  {
    id: "151",
    name: "Manjyot Saini",
    nickname: "INSAIN",
    designation: "Management Head",
    facebook: null,
    mail: "mailto:manjyotsaini1911@gmail.com",
    linkedin: "https://www.linkedin.com/in/manjyot-saini-559a7b271?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BihswaCCJRcGsZS0ecRcysA%3D%3D",
    github: null
  },
  {
    id: "152",
    name: "Gautham D Nair",
    nickname: "Got Em",
    designation: "Mechanical Head",
    facebook: null,
    mail: "mailto:gauthamdinilnair@gmail.com",
    linkedin: "https://www.linkedin.com/in/gautham-d-nair-bb230b274/?originalSubdomain=in",
    github: null
  },
  {
    id: "128",
    name: "Aditi Milind Joshi",
    nickname: "ADU DIDI",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:aditij0205@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "129",
    name: "Anirudh Agrawal",
    nickname: "DADDY ANIRUDH",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:anirudhagrawal6778@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "130",
    name: "David Jijo",
    nickname: "Belt-man",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:thisisdavidjijo@gmail.com",
    linkedin: "https://www.linkedin.com/in/david-jijo-a17a32288/",
    github: "https://github.com/AlliedLens"
  },
  {
    id: "131",
    name: "Subham Patra",
    nickname: "SUBHU DARLIN'",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:patrasubham2004@gmail.com",
    linkedin: "https://www.linkedin.com/in/subham-patra-94938526b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/AlphaGotReal"
  },
  {
    id: "132",
    name: "Vaishnavi Kulkarni",
    nickname: "ADITI",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:vaishnavik002@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "133",
    name: "Anaadhi Mongia",
    nickname: "ANAAGIN",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:anaadhimongia9+mns@gmail.com",
    linkedin: "https://www.linkedin.com/in/anaadhi-mongia-5890bb210/",
    github: "https://github.com/anaadhi/"
  },
  {
    id: "134",
    name: "Animesh Patil",
    nickname: "GOOS",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:animeshmanikpatil@gmail.com",
    linkedin: "https://www.linkedin.com/in/animesh-patil-248993266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/animeshpatil"
  },
  {
    id: "135",
    name: "Natasha Gonsalves",
    nickname: "NATZ",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:tashagonsalves0418@gmail.com",
    linkedin: "https://www.linkedin.com/in/natasha-gonsalves-32647529b/",
    github: null
  },
  {
    id: "136",
    name: "Siddharth Mathkar",
    nickname: "MAT KAR",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:siddharth.mathkar7@gmail.com",
    linkedin: "www.linkedin.com/in/siddharth-mathkar-314737246",
    github: null
  },
  {
    id: "137",
    name: "Sparsh Srivastava",
    nickname: "SPARK",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:sparsh1264@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "138",
    name: "Vansh Yadav",
    nickname: "VAN$",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:vanshyadav@live.com",
    linkedin: "https://www.linkedin.com/in/vansh-yadav-a7707121a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: "https://github.com/norsechurros"
  },
  {
    id: "139",
    name: "Anurag Raut",
    nickname: "ELAI",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:anuragelai@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "141",
    name: "Jaival Shah",
    nickname: "ORCUS",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:jaivalhshah@gmail.com",
    linkedin: "https://www.linkedin.com/in/jaival-shah-6a0310274?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    github: null
  },
  {
    id: "143",
    name: "Zain Hussain",
    nickname: "ZED",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:zainh1402@gmail.com",
    linkedin: null,
    github: null
  },
  {
    id: "144",
    name: "Chinmayi Bhat",
    nickname: "CHIPMUNK",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:chinmayibhat.in@gmail.com",
    linkedin: "https://in.linkedin.com/in/chinmayi-bhat-986a63246",
    github: null
  },
  {
    id: "145",
    name: "Diya Nisar",
    nickname: "DIYANASAUR",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:diya.nisar777@gmail.com",
    linkedin: "https://www.linkedin.com/in/diya-nisar-495375207/",
    github: null
  },
  {
    id: "146",
    name: "Eric B John",
    nickname: "eRIC ROLL",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:ericbinujohn@gmail.com",
    linkedin: "https://www.linkedin.com/in/eric-b-john46/",
    github: "https://github.com/Eric-B-J"
  }
];

export default members;
