const alums = [
  {
    id: "1",
    name: "Kumar Ranjan",
    nickname: "Team Leader (March, 2014 - July, 2015)",
    designation: "Co-Founder",
    facebook: "https://www.facebook.com/thelostmechanic",
    mail: "",
    linkedin: null,
    github: null,
    year: 2015
  },
  {
    id: "2",
    name: "Roshan Prakash",
    nickname: "Team Leader (July, 2015 - April, 2016)",
    designation: "Co-Founder",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null,
    year: 2016
  },
  {
    id: "3",
    name: "Shubham Verma",
    nickname: "",
    designation: "AI Head (March, 2014 - December, 2015)",
    facebook: null,
    mail: "",
    linkedin: "https://in.linkedin.com/in/shubhamvrm",
    github: null,
    year: 2015
  },
  {
    id: "4",
    name: "Tushar Sinha",
    nickname: "",
    designation: "Team Managar (July, 2015 - April, 2016)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/tushar-sinha-7ba08258",
    github: null,
    year: 2016
  },
  {
    id: "5",
    name: "Akash Deep",
    nickname: "",
    designation: "SnA Division Head (July, 2015 - April, 2016)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/akash-deep-tyagi-394ab7106/",
    github: null,
    year: 2016
  },
  {
    id: "6",
    name: "Harsh Barde",
    nickname: "HUB",
    designation: "Technical Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:harshbarde51@gmail.com",
    linkedin: "https://www.linkedin.com/in/harsh-barde-646304169",
    github: null,
    year: 2021
  },
  {
    id: "7",
    name: "Ritwik Agarwal",
    nickname: "Ritwik Agarwal",
    designation: "Team Manager (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:ritwikagarwal44@gmail.com",
    linkedin: "https://www.linkedin.com/in/ritwik-agarwal",
    github: "https://github.com/ritwik25",
    year: 2021
  },
  {
    id: "8",
    name: "Dhruv Joshi",
    nickname: "helium_balloons",
    designation: "AI Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:dhruvjoshi1007@gmail.com",
    linkedin: "https://www.linkedin.com/in/dhruv-joshi-b65a6b16a",
    github: "https://github.com/dhruvjoshi1007",
    year: 2021
  },
  {
    id: "9",
    name: "Arpit Chauhan",
    nickname: "Arpit Chauhan",
    designation: "S&A Head (March 2020- May 2021)",
    facebook: "https://www.facebook.com/profile.php?id=100026317991091",
    mail: "mailto:carpit680@gmail.com",
    linkedin: "https://www.linkedin.com/in/arpit-chauhan-bb84bb145",
    github: "https://github.com/carpit680",
    year: 2021
  },
  {
    id: "10",
    name: "Rohit Natu",
    nickname: "Rohit Natu",
    designation: "Mech Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:rohit.grandson@gmail.com",
    linkedin: "https://www.linkedin.com/in/rohit-natu-42b005180",
    github: "https://www.github.com/75072107",
    year: 2021
  },
  {
    id: "11",
    name: "Dhaval Machhar",
    nickname: "kRma",
    designation: "Management Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:dhavalmchhr@gmail.com",
    linkedin: "https://www.linkedin.com/in/dhaval-machhar-ba03191a0",
    github: "https://github.com/dhaval-machhar",
    year: 2021
  },
  {
    id: "12",
    name: "Anish Biswas",
    nickname: "trickarcher",
    designation: "AI Planning Head (March 2020- May 2021)",
    facebook: "https://www.facebook.com/theAnishBiswas",
    mail: "mailto:anishbiswas271@gmail.com",
    linkedin: "https://www.linkedin.com/in/anish-biswas",
    github: "https://github.com/trickarcher",
    year: 2021
  },
  {
    id: "13",
    name: "Akhil Bonagiri",
    nickname: "Akhil Bonagiri",
    designation: "Automation Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:bonagiri_akhil@yahoo.com",
    linkedin: "https://www.linkedin.com/in/akhil-bonagiri-2569b9181",
    github: null,
    year: 2021
  },
  {
    id: "14",
    name: "Gokul P",
    nickname: "Gokul P",
    designation: "Sensing Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:mailo:gokulgns@mailcom",
    linkedin: "htps://wwwlinkedin.com/in/gokul-p-38b22b154",
    github: "https//github.com/gokulp01",
    year: 2021
  },
  {
    id: "15",
    name: "Sarthake Choudhary",
    nickname: "",
    designation: "Team Leader (March, 2017 - March, 2018)",
    facebook: null,
    mail: "mailto:sarthak.choudhary24@gmail.com",
    linkedin: null,
    github: null,
    year: 2018
  },
  {
    id: "16",
    name: "Raj Nunes",
    nickname: "",
    designation: "Technical Head (March, 2017 - March, 2018",
    facebook: null,
    mail: "mailto:rrn.raj@gmail.com",
    linkedin: null,
    github: null,
    year: 2018
  },
  {
    id: "17",
    name: "Tanmay Agarwal",
    nickname: "",
    designation: "AI Head (March, 2017 - March, 2018",
    facebook: null,
    mail: "mailto:tanmayagr98@gmail.com",
    linkedin: null,
    github: null,
    year: 2018
  },
  {
    id: "18",
    name: "Kartikey Agarwal",
    nickname: "",
    designation: "Sensing and Automation Head (March, 2017 - March, 2018",
    facebook: null,
    mail: "mailto:kartikey04.05@gmail.com",
    linkedin: null,
    github: null,
    year: 2018
  },
  {
    id: "19",
    name: "Pranav Jain",
    nickname: "",
    designation: "Mechanical Head (March, 2017 - March, 2018",
    facebook: null,
    mail: "mailto:pranavjain977@gmail.com",
    linkedin: null,
    github: null,
    year: 2018
  },
  {
    id: "20",
    name: "Haran Ravindran",
    nickname: "",
    designation: "AI Member (March, 2017 - March, 2018)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/haran-ravindran-7220a3125/",
    github: null,
    year: 2018
  },
  {
    id: "21",
    name: "Abhishek Yadav",
    nickname: "",
    designation: "AI Member (March, 2017 - March, 2018)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/abhishek-yadav-seb/",
    github: null,
    year: 2018
  },
  {
    id: "22",
    name: "Ashish Mehta",
    nickname: "",
    designation: "Team Leader (April, 2016 - March, 2017)",
    facebook: null,
    mail: "mailto:ashishm20795@gmail.com",
    linkedin: null,
    github: null,
    year: 2017
  },
  {
    id: "23",
    name: "Reuben John",
    nickname: "",
    designation: "Technical Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "mailto:reubenvjohn@gmail.com",
    linkedin: null,
    github: null,
    year: 2017
  },
  {
    id: "24",
    name: "Surya Phatak",
    nickname: "",
    designation: "Team Manager (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/surya-phatak-a35b80118/",
    github: null,
    year: 2017
  },
  {
    id: "25",
    name: "Kartik Seshadri Chari",
    nickname: "",
    designation: "SnA Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/kartikchari/",
    github: null,
    year: 2017
  },
  {
    id: "26",
    name: "Tanay Verma",
    nickname: "",
    designation: "Mechanical Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/tanayvarma123/",
    github: null,
    year: 2017
  },
  {
    id: "27",
    name: "Shivani Modi",
    nickname: "",
    designation: "AI Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "mailto:sm5060@columbia.edu",
    linkedin: null,
    github: null,
    year: 2017
  },
  {
    id: "28",
    name: "Yash Bansod",
    nickname: "",
    designation: "Automation Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/yashbansod/",
    github: null,
    year: 2017
  },
  {
    id: "29",
    name: "Phanikrishna Kalluri",
    nickname: "",
    designation: "Mechanics Head (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/phanikrishna-kalluri-6b5b45118/",
    github: null,
    year: 2017
  },
  {
    id: "30",
    name: "Kaushik Nath",
    nickname: "",
    designation: "Perception Head (Sep, 2016 - Aug, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/kaushikmit/",
    github: null,
    year: 2017
  },
  {
    id: "31",
    name: "Debang Sharma",
    nickname: "",
    designation: "SnA Member (April, 2016 - March, 2017)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/dbngshrma/",
    github: null,
    year: 2017
  },
  {
    id: "32",
    name: "Fateh Singh",
    nickname: "",
    designation: "AI Member (April, 2016 - March, 2017)",
    facebook: null,
    mail: "mailto:fateh288@gmail.com",
    linkedin: null,
    github: null,
    year: 2017
  },
  {
    id: "33",
    name: "Dheeraj R. Reddy",
    nickname: "Squadrick",
    designation: "Team Leader (March, 2018 - March, 2019)",
    facebook: null,
    mail: "mailto:dheeraj98reddy@gmail.com",
    linkedin: "https://www.linkedin.com/in/dheeraj-rajaram-reddy/",
    github: "https://squadrick.dev",
    year: 2019
  },
  {
    id: "34",
    name: "Mukesh Kumar",
    nickname: "Mukesh Kumar",
    designation: "Tech Head (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/dheeraj.r.reddy",
    mail: "mailto:mukeshkumar199739@gmail.com",
    linkedin: null,
    github: null,
    year: 2019
  },
  {
    id: "35",
    name: "Sanjeev Nayak",
    nickname: "Sanjeev Nayak",
    designation: "Team Manager (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/sanjeev.nayak.39545",
    mail: "mailto:sanjeevnayak9@gmail.com",
    linkedin: "https://www.linkedin.com/mwlite/me",
    github: null,
    year: 2019
  },
  {
    id: "36",
    name: "Sarthak Mittal",
    nickname: "naivehobo",
    designation: "AI Head (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/sarthak.mittal2",
    mail: "mailto:sarthakmittal2608@gmail.com",
    linkedin: null,
    github: "https://github.com/naiveHobo",
    year: 2019
  },
  {
    id: "37",
    name: "Pranjal Sinha",
    nickname: "Pranjal Sinha",
    designation: "SnA Head (March, 2018 - March, 2019)",
    facebook: null,
    mail: "",
    linkedin: "https://www.linkedin.com/in/pranjal-sinha-457114138/",
    github: null,
    year: 2019
  },
  {
    id: "38",
    name: "Ansh Verma",
    nickname: "Ansh Verma",
    designation: "Mechanical Head (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/vermaansh135",
    mail: "",
    linkedin: "https://www.linkedin.com/in/ansh-verma-b5607713a/",
    github: null,
    year: 2019
  },
  {
    id: "39",
    name: "Apratim Mukhrjee",
    nickname: "Apratim Mukhrjee",
    designation: "AI Perception Head (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/opletts",
    mail: "mailto:apratim101@gmail.com",
    linkedin: "https://www.linkedin.com/in/apratim-mukherjee/",
    github: "https://opletts.github.io",
    year: 2019
  },
  {
    id: "40",
    name: "Shreyas Sharma",
    nickname: "Shreyas Sharma",
    designation: "AI Planning Head (March, 2018 - March, 2019)",
    facebook: null,
    mail: "mailto:shreyas.rox101@gmail.com",
    linkedin: "https://www.linkedin.com/in/shreyas-sharma-443680130/",
    github: "https://github.com/shreyashub",
    year: 2019
  },
  {
    id: "41",
    name: "Vishnu Menon",
    nickname: "Vishnu Menon",
    designation: "Mech Mechanics Head (March, 2018 - March, 2019)",
    facebook: "https://m.facebook.com/vishnu.menon.946",
    mail: "mailto:vishnumenon9898@gmail.com",
    linkedin: null,
    github: null,
    year: 2019
  },
  {
    id: "42",
    name: "Damodar Nayak",
    nickname: "Damodar Nayak",
    designation: "Mech Senior Member (March, 2018 - March, 2019)",
    facebook: null,
    mail: "",
    linkedin: null,
    github: null,
    year: 2019
  },
  {
    id: "43",
    name: "Johir Suresh",
    nickname: "Johir Suresh",
    designation: "Mech Senior Member (March, 2018 - March, 2019)",
    facebook: "https://www.facebook.com/johir.suresh",
    mail: "mailto:johirsuresh1998@gmail.com",
    linkedin: "https://www.linkedin.com/in/johir-suresh-59252a129/",
    github: null,
    year: 2019
  },
  {
    id: "44",
    name: "Tanuj Agarwal",
    nickname: "Tanuj Agarwal",
    designation: "Mech Mechanics Member (March, 2018 - March, 2019)",
    facebook: null,
    mail: "mailto:tanujma99@gmail.com",
    linkedin: "https://www.linkedin.com/in/tanuj-agrawal-88a149139",
    github: null,
    year: 2019
  },
  {
    id: "45",
    name: "Shrijit Singh",
    nickname: "",
    designation: "Tech Head (March, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/shrijitsingh99",
    mail: "mailto:shrijitsingh99@gmail.com",
    linkedin: "https://www.linkedin.com/in/shrijit-singh-81b86a145/",
    github: "https://github.com/shrijitsingh99",
    year: 2020
  },
  {
    id: "46",
    name: "Tanaya Mandke",
    nickname: "",
    designation: "Team Manager (March, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/tana.mandke",
    mail: "mailto:Tanaya.mandke9299@gmail.com",
    linkedin: "http://linkedin.com/in/tanaya-mandke-885b24165",
    github: null,
    year: 2020
  },
  {
    id: "47",
    name: "Shivesh Khaitan",
    nickname: "",
    designation: "Head of Robotics (March, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/shivesh.khaitan",
    mail: "mailto:shiveshkhaitan@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivesh-khaitan-b48225159/",
    github: "https://github.com/shiveshkhaitan",
    year: 2020
  },
  {
    id: "48",
    name: "Rishab Agarwal",
    nickname: "",
    designation: "S&A Head (March, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/Agarwal.Rishab.99",
    mail: "mailto:rishabgrwl121@gmail.com",
    linkedin: "https://www.linkedin.com/in/rishab-agarwal-8a959216a",
    github: null,
    year: 2020
  },
  {
    id: "49",
    name: "Arya Karani",
    nickname: "",
    designation: "Mechanical Head (March, 2019 - March, 2020)",
    facebook: null,
    mail: "mailto:karani.arya@gmail.com",
    linkedin: "https://www.linkedin.com/in/surampalli-rakesh-450213160/",
    github: null,
    year: 2020
  },
  {
    id: "50",
    name: "Sarathkrishnan Ramesh",
    nickname: "",
    designation: "AI Planning Head (March, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/sarathkrishnan.ramesh",
    mail: "mailto:sarathkrishnan99@gmail.com",
    linkedin: "https://www.linkedin.com/in/sarathkrishnan-ramesh-0a75a315b",
    github: "https://www.github.com/Sarath18",
    year: 2020
  },
  {
    id: "51",
    name: "Shivanshu Agarwal",
    nickname: "",
    designation: "Sensing Head (March, 2019 - March, 2020)",
    facebook: "https://w ww.facebook.com/shivanshu.agarwal.965",
    mail: "mailto:agarwalshivanshu00@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivansu-agarwal-53b625136",
    github: "https://github.com/shic15",
    year: 2020
  },
  {
    id: "52",
    name: "Dheeraj Mohan",
    nickname: "",
    designation: "AI Perception Head (March, 2019 - March, 2020)",
    facebook: "https://facebook.com/dheeraj.McDhee",
    mail: "mailto:dheeraj.98@outlook.com",
    linkedin: "https://www.linkedin.com/in/dheeraj-mcdhee",
    github: "https://www.github.com/dheeraj-coding",
    year: 2020
  },
  {
    id: "53",
    name: "Dasarath S",
    nickname: "",
    designation: "Automation Head (March, 2019 - March, 2020)",
    facebook: "https://facebook.com/dasarath.selvakumar",
    mail: "mailto:dasarathselvakumar@gmail.com",
    linkedin: "https://www.linkedin.com/in/dasarath-s-66525b157",
    github: null,
    year: 2020
  },
  {
    id: "54",
    name: "Siddarth Venkatraman",
    nickname: "",
    designation: "AI Planning Member (Feb, 2018 - March, 2020)",
    facebook: null,
    mail: "mailto:siddarthegreat@gmail.com",
    linkedin: "https://www.linkedin.com/in/siddarth-venkatraman-59b863157/",
    github: "https://github.com/HyperPotatoNeo",
    year: 2020
  },
  {
    id: "55",
    name: "Ansel Dias",
    nickname: "",
    designation: "S&A Sensing Member (Feb, 2018 - March, 2020)",
    facebook: "https://www.facebook.com/profile.php?id=100010693602083&ref=br_rs",
    mail: "mailto:thegreatanseldias@gmail.com",
    linkedin: "https://www.linkedin.com/in/ansel-dias-a7993215b/",
    github: null,
    year: 2020
  },
  {
    id: "56",
    name: "Raj Tulluri",
    nickname: "",
    designation: "S&A Automation Member (Jan, 2019 - March, 2020)",
    facebook: null,
    mail: "mailto:mail@rajabbastulluri.com",
    linkedin: "https://www.linkedin.com/in/raj-tulluri-165977145/",
    github: null,
    year: 2020
  },
  {
    id: "57",
    name: "Chaitanya S",
    nickname: "",
    designation: "AI Perception Member (Feb, 2018 - March, 2020)",
    facebook: "https://www.facebook.com/chaitanya.shyamaraj.37",
    mail: "mailto:chaitanya2.1999@gmail.com",
    linkedin: "https://www.linkedin.com/in/chaitanya21999",
    github: "https://github.com/Chaitanya-git",
    year: 2020
  },
  {
    id: "58",
    name: "Anurag Borkar",
    nickname: "",
    designation: "AI Perception Member (Feb, 2019 - March, 2020)",
    facebook: "https://www.facebook.com/anurag.borkar.376",
    mail: "mailto:anuragvborkar@gmail.com",
    linkedin: "https://www.linkedin.com/in/anurag-borkar-9b3645166",
    github: "https://github.com/anuragvborkar",
    year: 2020
  },
  {
    id: "59",
    name: "Parthesh Savla",
    nickname: "",
    designation: "Mechanics Member (Feb, 2018 - March, 2020)",
    facebook: "https://www.facebook.com/prince.savla.9",
    mail: "mailto:parthesh.savla@gmail.com",
    linkedin: "https://www.linkedin.com/in/parthesh-savla327322163",
    github: null,
    year: 2020
  },
  {
    id: "60",
    name: "Adheesh H M",
    nickname: "",
    designation: "AI Perception Member (Feb, 2018 - March, 2020)",
    facebook: null,
    mail: "mailto:adheesh1010@gmail.com",
    linkedin: "https://www.linkedin.com/in/adheesh-h-m-14283912b",
    github: null,
    year: 2020
  },
  {
    id: "61",
    name: "Aditya Veerabahu",
    nickname: "Aditya Veerabahu",
    designation: "Admin & Finance Head (2019 - 2020)",
    facebook: "https://www.facebook.com/profile.php?id=100008935382776",
    mail: "mailto:adityaveerabahu@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-veerabahu",
    github: "https://github.com/adityaveera17",
    year: 2020
  },
  {
    id: "62",
    name: "Abhineet Choudhary",
    nickname: "Abhineet Choudhary",
    designation: "AI Perception Head (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:abhi.chou4@gmail.com",
    linkedin: "https://www.linkedin.com/in/abhineet-c-344967bb/",
    github: "https://github.com/abhichou4",
    year: 2021
  },
  {
    id: "63",
    name: "Aniket Bhawe",
    nickname: "Aniket Bhawe",
    designation: "Mechanics Head (March 2020- May 2021)",
    facebook: "https://www.facebook.com/aniket.bhawe.3",
    mail: "mailto:kiwiani99@gmail.com",
    linkedin: "http://linkedin.com/in/aniket-bhawe",
    github: null,
    year: 2021
  },
  {
    id: "64",
    name: "Debayan Deb",
    nickname: "Devayern",
    designation: "Design Head (March 2020- May 2021)",
    facebook: "https://www.facebook.com/profile.php?id=100008758214121",
    mail: "mailto:devion008@gmail.com",
    linkedin: "https://www.linkedin.com/in/debayan-deb-10311617b",
    github: null,
    year: 2021
  },
  {
    id: "65",
    name: "Baidyanath Kundu",
    nickname: "sudo-panda",
    designation: "Senior AI Member (March 2020- May 2021)",
    facebook: "https://www.facebook.com/Baidya99",
    mail: "mailto:kundubaidya99@gmail.com",
    linkedin: "https://www.linkedin.com/in/baidyanath-kundu-025b41142",
    github: "https://github.com/sudo-panda",
    year: 2021
  },
  {
    id: "66",
    name: "Sahil Khose",
    nickname: "Sahil Khose",
    designation: "Senior AI Member (March 2020- May 2021)",
    facebook: "https://www.facebook.com/sahil.khose.3",
    mail: "mailto:sahilkhose18@gmail.com",
    linkedin: "https://www.linkedin.com/in/sahil-khose-20a621166/",
    github: "https://github.com/sahilkhose",
    year: 2021
  },
  {
    id: "67",
    name: "Aneesh Chawla",
    nickname: "aneesh404",
    designation: "Senior AI Member (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:aneeshchawla404@gmail.com",
    linkedin: "https://www.linkedin.com/in/aneesh-chawla-986165171",
    github: "https://github.com/aneesh404",
    year: 2021
  },
  {
    id: "68",
    name: "Garima Singh",
    nickname: "grimmyshini",
    designation: "Senior AI Member (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:garimasingh0028@gmail.com",
    linkedin: "https://www.linkedin.com/in/garima-singh-392856189/",
    github: "https://github.com/grimmmyshini",
    year: 2021
  },
  {
    id: "69",
    name: "Kishore K",
    nickname: "Kishore K",
    designation: "Senior S&A Member (March 2020- May 2021)",
    facebook: null,
    mail: "mailto:klk.kishore5@gmail.com",
    linkedin: "https://www.linkedin.com/in/kishorek31/",
    github: null,
    year: 2021
  },
  {
    id: "70",
    name: "Shoumik Dey",
    nickname: "Shoumik Dey",
    designation: "Senior S&A Member (March 2020- May 2021)",
    facebook: "http://facebook.com/shoumik.shonai",
    mail: "mailto:shoumikdey123@gmail.com",
    linkedin: "http://linkedin.com/in/shoumik-dey-53b69b16a",
    github: "https://github.com/shoumikdey",
    year: 2021
  },
  {
    id: "71",
    name: "Sai Manish",
    nickname: "Sai Manish",
    designation: "Senior Mechanical Member (March 2020- May 2021)",
    facebook: "https://www.facebook.com/sai.manish.1270",
    mail: "mailto:saimanish12@gmail.com",
    linkedin: "https://www.linkedin.com/in/sai-manish-107368169",
    github: null,
    year: 2021
  },
  {
    id: "72",
    name: "Asish Boggavarapu",
    nickname: "Asish Boggavarapu",
    designation: "Senior Mechanical Member (March 2020- May 2021)",
    facebook: "https://www.facebook.com/asish.boggavarapu",
    mail: "mailto:asishb212@gmail.com",
    linkedin: "https://www.linkedin.com/in/asish-boggavarapu-09bba2166",
    github: null,
    year: 2021
  },
  {
    id: "73",
    name: "Yatharth Agarwal",
    nickname: "Yatharth Agarwal",
    designation: "Technical Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:yatharthrpi@gmail.com",
    linkedin: "https://www.linkedin.com/in/yatharth-agarwal-5123911a5",
    github: null,
    year: 2022
  },
  {
    id: "74",
    name: "Kallol Saha",
    nickname: "Failed_Mesh",
    designation: "Team Manager (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:kallolsaha1752001@gmail.com",
    linkedin: "https://www.linkedin.com/in/kallol-saha-45b5a41a3/",
    github: "https://github.com/FailedMesh",
    year: 2022
  },
  {
    id: "75",
    name: "Anshumaan Singh",
    nickname: "Anshu-man567",
    designation: "AI Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:anshumaan567@gmail.com",
    linkedin: "https://www.linkedin.com/in/anshumaan-singh-ab1ba91b3/",
    github: "https://github.com/Anshu-man567",
    year: 2022
  },
  {
    id: "76",
    name: "Raghav Thakar",
    nickname: "Tunak",
    designation: "Sensing & Automation Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:raghavthakar12@gmail.com",
    linkedin: "https://www.linkedin.com/in/raghavthakar/",
    github: "https://github.com/raghavthakar",
    year: 2022
  },
  {
    id: "77",
    name: "Ashwin Disa",
    nickname: "AshD",
    designation: "Mechanical Head (May 2021- June 2022)",
    facebook: "https://m.facebook.com/ashwin.disa",
    mail: "mailto:ashwin.disa@gmail.com",
    linkedin: "https://in.linkedin.com/in/ashwin-disa-725827180",
    github: null,
    year: 2022
  },
  {
    id: "78",
    name: "Naman Joshi",
    nickname: "NimWilliams",
    designation: "Management Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:joshinaman14@gmail.com",
    linkedin: "https://www.linkedin.com/in/naman-joshi-476a07195/",
    github: "https://github.com/JoshiNaman",
    year: 2022
  },
  {
    id: "79",
    name: "Aryan Roy",
    nickname: "Le_King",
    designation: "AI Perception Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:aryanroy5678@gmail.com",
    linkedin: "https://www.linkedin.com/in/aryan-roy-914458189",
    github: null,
    year: 2022
  },
  {
    id: "80",
    name: "Shivansh Bakshi",
    nickname: "Worpe",
    designation: "AI Planning Head (May 2021- June 2022)",
    facebook: "https://www.facebook.com/shivansh.baks",
    mail: "mailto:shivansh.bakshi@gmail.com",
    linkedin: "https://www.linkedin.com/in/shivansh-bakshi/",
    github: "https://github.com/Shivansh-Bakshi",
    year: 2022
  },
  {
    id: "81",
    name: "Aditya Nirmale",
    nickname: "Crazzy",
    designation: "Sensing Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:adityanirmaleofficial@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-nirmale/",
    github: null,
    year: 2022
  },
  {
    id: "82",
    name: "Akshat Pandey",
    nickname: "0wL",
    designation: "Automation Head (May 2021- June 2022)",
    facebook: "https://www.facebook.com/akshat.pandey.35175633",
    mail: "mailto:akshat.darkmatter@gmail.com",
    linkedin: "https://www.linkedin.com/in/akshat-pandey-011b811a2/",
    github: "https://github.com/akshatowl",
    year: 2022
  },
  {
    id: "83",
    name: "Twinkle Goyal",
    nickname: "Deadlysalmon99",
    designation: "Mechanics Head (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:manigoyal08@gmail.com",
    linkedin: "https://www.linkedin.com/in/twinkle-goyal-1236ba154/",
    github: null,
    year: 2022
  },
  {
    id: "84",
    name: "Srujan Kulkarni",
    nickname: "Srujan Kulkarni",
    designation: "Design Head (May 2021- June 2022)",
    facebook: "https://www.facebook.com/srujan.kulkarni.7/",
    mail: "mailto:kulkarnisrujan77@gmail.com",
    linkedin: "http://www.linkedin.com/in/srujan-kulkarni-3378351b2",
    github: null,
    year: 2022
  },
  {
    id: "85",
    name: "Sridurga Krithivasan",
    nickname: "Durgzzz",
    designation: "PR and Publicity Head (May 2021- June 2022)",
    facebook: "https://www.facebook.com/sridurga.krithivasan",
    mail: "mailto:sridurga.krithi@gmail.com",
    linkedin: "http://linkedin.com/in/sridurga-krithivasan-b49075190",
    github: null,
    year: 2022
  },
  {
    id: "86",
    name: "Sathvik N K",
    nickname: "S@_o7",
    designation: "Admin and Finance Head (May 2021- June 2022)",
    facebook: "https://www.facebook.com/sathvik.nk.1",
    mail: "mailto:sathviknk14@gmail.com",
    linkedin: "https://www.linkedin.com/in/sathvik-nk-4956311a4",
    github: null,
    year: 2022
  },
  {
    id: "87",
    name: "Atula Tejaswi",
    nickname: "atutej",
    designation: "AI Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:atulatejaswi@gmail.com",
    linkedin: "https://www.linkedin.com/in/atula-tejaswi-neerkaje-4b989b157",
    github: "https://github.com/atutej",
    year: 2022
  },
  {
    id: "88",
    name: "Ishika Jaiswal",
    nickname: "cinnamonroll",
    designation: "AI Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:jaiswalishikacrystal@gmail.com",
    linkedin: "https://www.linkedin.com/in/ishika-jaiswal-720b10193",
    github: "https://github.com/Ishikajaiswal",
    year: 2022
  },
  {
    id: "89",
    name: "Kaustav Sarkar",
    nickname: "Nightfury",
    designation: "AI Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:kaustavsarkar2001@gmail.com",
    linkedin: "https://www.linkedin.com/in/kaustavsarkar/",
    github: "https://github.com/Kaustav-Sarkar",
    year: 2022
  },
  {
    id: "90",
    name: "Shrey Bihani",
    nickname: "Shrey Bihani",
    designation: "AI Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:shreybihani13@gmail.com",
    linkedin: null,
    github: null,
    year: 2022
  },
  {
    id: "91",
    name: "Shruti Jain",
    nickname: "Shruti Jain",
    designation: "AI Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:shrutipraveenjain@gmail.com",
    linkedin: "https://www.linkedin.com/in/shruti-jain-81a7581b4/",
    github: null,
    year: 2022
  },
  {
    id: "92",
    name: "Tanaya Gupte",
    nickname: "Sin(aya)/Cos(aya)",
    designation: "Mechanical Member (May 2021- June 2022)",
    facebook: null,
    mail: "mailto:tanaya1707@gmail.com",
    linkedin: "https://www.linkedin.com/in/tanaya-gupte-4313931a5/",
    github: "https://github.com/tanaya1707",
    year: 2022
  },
  {
    id: "93",
    name: "Nilabha Das",
    nickname: "N3rdys",
    designation: "Technical Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:nilabha.das@learner.manipal.edu",
    linkedin: "https://www.linkedin.com/in/nilabhadas314",
    github: "https://github.com/N3dys",
    year: 2023
  },
  {
    id: "94",
    name: "Deep Gupta",
    nickname: "DeeplyDumb",
    designation: "Team Manager (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:deepgupta2508@gmail.com",
    linkedin: "https://www.linkedin.com/in/deep-gupta-35b927204/",
    github: "https://github.com/deep-25",
    year: 2023
  },
  {
    id: "95",
    name: "Aditya Balaji",
    nickname: "Aditya Balaji",
    designation: "Research & Development Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:adityabalaji3@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    id: "96",
    name: "Aaron Jomy",
    nickname: "Aaron Jomy",
    designation: "AI & Robotics Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:aaronjomyjoseph@gmail.com",
    linkedin: "https://www.linkedin.com/in/aaron-jomy-2312a1201",
    github: "https://github.com/maximusron",
    year: 2023
  },
  {
    id: "97",
    name: "Tushar Upadhyay",
    nickname: "Invok3",
    designation: "Sensing & Automation Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:tushar.u110@gmail.com",
    linkedin: "https://www.linkedin.com/in/tushar-upadhyay-wiz/",
    github: "https://github.com/tushar-wiz",
    year: 2023
  },
  {
    id: "98",
    name: "Divyam Shah",
    nickname: "Big Daddy D",
    designation: "Mechanical Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:yamdiv.02@gmail.com",
    linkedin: "https://www.linkedin.com/in/divyam-shah-61bb8a219",
    github: null,
    year: 2023
  },
  {
    id: "99",
    name: "Amogh Rao",
    nickname: "kowalski",
    designation: "Management Head (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:amogh1515@gmail.com",
    linkedin: "https://www.linkedin.com/in/amogh-vivekananda-rao-62721b19b",
    github: null,
    year: 2023
  },
  {
    id: "100",
    name: "Sai Anish Malla",
    nickname: "Spec_he",
    designation: "Senior AI Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:mallasaianish@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    id: "101",
    name: "Ayush Kumar",
    nickname: "Alcazar",
    designation: "Senior AI Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:ayush_7102@yahoo.in",
    linkedin: "https://www.linkedin.com/in/ayushk7102/",
    github: "https://github.com/ayushk7102",
    year: 2023
  },
  {
    id: "102",
    name: "Neetigya Poddar",
    nickname: "N3rdys",
    designation: "Senior AI Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:neetigyapoddar1@gmail.com",
    linkedin: "https://www.linkedin.com/in/neetigya-poddar-a022811b6/",
    github: "https://github.com/NeetigyaPod",
    year: 2023
  },
  {
    id: "103",
    name: "Aditya Choubey",
    nickname: "Dulo Peepo",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:adityachoubey2512@gmail.com",
    linkedin: "https://www.linkedin.com/in/aditya-choubey-20bb9a1b3",
    github: "https://github.com/aditya-c2512",
    year: 2023
  },
  {
    id: "104",
    name: "Darshan K S",
    nickname: "HomeZ",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:darshanks.work@gmail.com",
    linkedin: "https://www.linkedin.com/in/darshan-k-s/",
    github: "https://github.com/darshan-k-s",
    year: 2023
  },
  {
    id: "105",
    name: "Y Vishal Rao",
    nickname: "Mongoose",
    designation: "Senior Sensing & Automation Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:yellurvishalrao@gmail.com",
    linkedin: null,
    github: null,
    year: 2023
  },
  {
    id: "106",
    name: "Shreyas Bhat",
    nickname: "＼(ﾟ ｰﾟ＼)",
    designation: "Senior Mechanical Member (August 2022 - August 2023)",
    facebook: null,
    mail: "mailto:shreyasbhat17@gmail.com",
    linkedin: null,
    github: "https://github.com/Shreyas-gits",
    year: 2023
  },
  {
    id: "107",
    name: "Diya Dhabade",
    nickname: "Diya Dhabade",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:diyaddhabade@gmail.com",
    linkedin: "https://www.linkedin.com/in/diya-dhabade-7267b6210/",
    github: "https://github.com/DiyaDhabade",
    year: 2023
  },
  {
    id: "108",
    name: "Aman Pandey",
    nickname: "Salamander",
    designation: "Technical Head",
    facebook: null,
    mail: "mailto:aman.pandey232003@gmail.com",
    linkedin: "https://www.linkedin.com/in/aman-pandey-4b0a25166",
    github: "https://github.com/aman-pandey23",
    year: 2024
  },
  {
    id: "109",
    name: "Eshaan Shah",
    nickname: "중국어",
    designation: "Team Manager",
    facebook: null,
    mail: "mailto:eshaan.shah@gmail.com",
    linkedin: "https://www.linkedin.com/in/eshaan-shah-06b256215",
    github: "https://github.com/plus-4",
    year: 2024
  },
  {
    id: "110",
    name: "Yashas Ranjan",
    nickname: "Yashas",
    designation: "Research & Development Head",
    facebook: null,
    mail: "mailto:yashasranjan2013@gmail.com",
    linkedin: null,
    github: "https://github.com/sprrrry",
    year: 2024
  },
  {
    id: "111",
    name: "Divyam Chandalia",
    nickname: "Chandu",
    designation: "AI Head",
    facebook: null,
    mail: "mailto:divyamchandalia3@gmail.com",
    linkedin: "https://www.linkedin.com/in/divyam-chandalia-73974927b/",
    github: "https://github.com/DivyamChandalia",
    year: 2024
  },
  {
    id: "112",
    name: "Anjas Biswas",
    nickname: "Anjazsh",
    designation: "Sensing and Automation Head",
    facebook: null,
    mail: "mailto:anjasbiswas@gmail.com",
    linkedin: "https://www.linkedin.com/in/anjas-biswas-a07851227",
    github: "https://github.com/AnjasBiswas",
    year: 2024
  },
  {
    id: "113",
    name: "Prajwal Govind Bhat",
    nickname: "Brown Panther",
    designation: "Mechanical Head",
    facebook: null,
    mail: "mailto:prajwalgb03@gmail.com",
    linkedin: "https://www.linkedin.com/in/prajwal-govind-86789a247",
    github: null,
    year: 2024
  },
  {
    id: "114",
    name: "Ananya Choubey",
    nickname: "choubs",
    designation: "Management Head",
    facebook: null,
    mail: "mailto:ananya.choubey3@gmail.com",
    linkedin: "https://www.linkedin.com/in/ananya-choubey-1101231b0",
    github: null,
    year: 2024
  },
  {
    id: "115",
    name: "Khalaq Shah",
    nickname: "Kajra Re",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:deepgupta2508@gmail.com",
    linkedin: "https://www.linkedin.com/in/khalaq-shah",
    github: "https://github.com/Khalaq-Shah",
    year: 2024
  },
  {
    id: "116",
    name: "Nathan Adrian Saldanha",
    nickname: "Nathan",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:nathansaldanha02@gmail.com",
    linkedin: "https://www.linkedin.com/in/nathan-adrian-saldanha/",
    github: "https://github.com/HyperToken9",
    year: 2024
  },
  {
    id: "117",
    name: "Rudra Patel",
    nickname: "Ranguy",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:rudrapatel9304@gmail.com",
    linkedin: "https://www.linkedin.com/in/rudra-patel-6222821aa",
    github: "https://github.com/ranguy9304",
    year: 2024
  },
  {
    id: "118",
    name: "Samik Pujari",
    nickname: "shrexy",
    designation: "Senior AI Member",
    facebook: null,
    mail: "mailto:samikp149@gmail.com",
    linkedin: null,
    github: null,
    year: 2024
  },
  {
    id: "119",
    name: "Yuvraj Bhaia",
    nickname: "Sleepwithyuvz",
    designation: "Senior Sensing and Automation Member",
    facebook: null,
    mail: "mailto:yuvrajbhaiaaa@gmail.com",
    linkedin: "https://www.linkedin.com/in/yuvraj-bhaia-88911420",
    github: null,
    year: 2024
  },
  {
    id: "120",
    name: "Chintam Sumanth Reddy",
    nickname: "Chintham",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:chinthamreddy22@gmail.com",
    linkedin: null,
    github: null,
    year: 2024
  },
  {
    id: "121",
    name: "Mahati Balaji",
    nickname: "mahatea",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:mahati.balaji@gmail.com",
    linkedin: null,
    github: null,
    year: 2024
  },
  {
    id: "122",
    name: "Riya Kulkarni",
    nickname: "Rio",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:riyamkulkarni@gmail.com",
    linkedin: "https://www.linkedin.com/in/riya-kulkarni-718a7a233",
    github: null,
    year: 2024
  },
  {
    id: "123",
    name: "Shreyas Gali",
    nickname: "Shreyu",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:gshreyas2203@gmail.com",
    linkedin: "https://www.linkedin.com/in/shreyas-gali-5baab9207",
    github: null,
    year: 2024
  },
  {
    id: "124",
    name: "Yash Sethia",
    nickname: "YASH",
    designation: "Senior Mechanical Member",
    facebook: null,
    mail: "mailto:yash.sethia@learner.manipal.edu",
    linkedin: "https://www.linkedin.com/in/yash-sethia-320462188",
    github: null,
    year: 2024
  },
  {
    id: "125",
    name: "Anshita Jaiswal",
    nickname: "myr",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:anshitaj038@gmail.com",
    linkedin: "https://www.linkedin.com/in/anshita-jaiswal-243756238",
    github: null,
    year: 2024
  },
  {
    id: "126",
    name: "Jay Patel",
    nickname: "Patss",
    designation: "Senior Management Member",
    facebook: null,
    mail: "mailto:jayyypatel24@gmail.com",
    linkedin: "https://www.linkedin.com/in/jay-patel-b59206227",
    github: null,
    year: 2024
  }
];

export default alums;
